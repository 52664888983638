import React, { ReactNode, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FormFieldMetadata, FormFieldType } from 'Components/FormGenerator/FormGenerator.types';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  ListInput,
  SHOW_PICKER_TIME_PROPS,
  Select,
  TreeSelect
} from 'Components/Primitives';
import {
  getFormGeneratorFieldPathName,
  getFormGeneratorFieldRules,
  getFormGenratorFieldTestId
} from 'Components/FormGenerator/FormGeneratorUtils';
import classNames from 'classnames';
import { ListSelect } from 'Components/ListSelect';
import { BooleanSelect } from 'Components/BooleanSelect';
import { DateRangeSelect } from 'Components/DateRangeSelect';

interface FormGeneratorFieldProps {
  metadata: FormFieldMetadata;
  className?: string;
}

const StyledFormItem = styled(Form.Item)`
  .form-field-label {
    display: flex;
    align-items: center;
  }
  .label-suffix {
    display: flex;
  }
  .label-suffix,
  .form-field-label-info-icon {
    margin-left: 4px;
  }
`;

export const FormGeneratorField: React.FC<FormGeneratorFieldProps> = ({ metadata, className }) => {
  const { t } = useTranslation(['common']);
  const fieldRules = useMemo(() => getFormGeneratorFieldRules(metadata), [metadata]);
  const valuePropName = useMemo(
    (): 'checked' | 'value' =>
      metadata.fieldType === FormFieldType.Boolean && metadata.booleanRendering === 'checkbox' ? 'checked' : 'value',
    [metadata]
  );

  const getFormField = useCallback(
    (fmd: FormFieldMetadata): ReactNode => {
      switch (fmd.fieldType) {
        case FormFieldType.String:
          return fmd.stringOptions?.mode === 'textArea' ? (
            <Input.TextArea
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.string-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              rows={3}
            />
          ) : (
            <Input
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.string-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
            />
          );
        case FormFieldType.Number:
          return (
            <InputNumber
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.number-placeholder', { fieldName: fmd.label })}
              disabled={fmd.disabled}
              controls={false}
            />
          );
        case FormFieldType.Password:
          return (
            <Input.Password
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.string-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
            />
          );
        case FormFieldType.Date:
          return (
            <DatePicker
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.date-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              disabledDate={fmd.disabledDate}
            />
          );
        case FormFieldType.DateTime:
          return (
            <DatePicker
              showTime={SHOW_PICKER_TIME_PROPS}
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.date-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              disabledDate={fmd.disabledDate}
            />
          );
        case FormFieldType.DateRange:
          return (
            <DateRangeSelect
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('form.date-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              withPrefix={fmd.dateOptions?.withPrefix}
              predefinedOptions={fmd.dateOptions?.predefinedOptions}
            />
          );
        case FormFieldType.List:
          return fmd.customOptions ? (
            <Select
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              options={fmd.customOptions}
              mode={fmd.listOptions?.mode}
              placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
              showSearch={fmd.customOptions.length > 3}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              maxTagCount={fmd.listOptions?.maxTagCount}
              emptyAsAll={fmd.listOptions?.emptyAsAll || false}
            />
          ) : (
            <ListSelect
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              type={fmd.listType}
              mode={fmd.listOptions?.mode}
              placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              customOptions={fmd.customOptions}
              maxTagCount={fmd.listOptions?.maxTagCount}
              emptyAsAll={fmd.listOptions?.emptyAsAll || false}
            />
          );
        case FormFieldType.TreeList:
          return (
            <TreeSelect
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              selectMultiple={fmd.listOptions?.mode === 'multiple'}
              placeholder={fmd.placeholder || t('form.select-placeholder', { fieldName: fmd.label })}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
              options={fmd.treeOptions}
              maxTagCount={fmd.listOptions?.maxTagCount}
              popupMatchSelectWidth={fmd.listOptions?.popupMatchSelectWidth}
              treeNodeLabelProp={fmd.listOptions?.treeNodeLabelProp}
              emptyAsAll={fmd.listOptions?.emptyAsAll || false}
            />
          );
        case FormFieldType.Boolean:
          return fmd.booleanRendering === 'checkbox' ? (
            <Checkbox data-testid={getFormGenratorFieldTestId(fmd.id.toString())} disabled={fmd.disabled}>
              {fmd.placeholder || t('boolean-cb.placeholder')}
            </Checkbox>
          ) : (
            <BooleanSelect
              data-testid={getFormGenratorFieldTestId(fmd.id.toString())}
              placeholder={fmd.placeholder || t('boolean-select.placeholder')}
              allowClear={fmd.allowClear === undefined ? true : fmd.allowClear}
              disabled={fmd.disabled}
            />
          );
      }
    },
    [t]
  );

  return metadata.fieldType === FormFieldType.StringArray ? (
    <ListInput
      metadata={metadata}
      className={classNames('form-generator-field', getFormGenratorFieldTestId(metadata.id.toString()), className)}
    />
  ) : (
    <StyledFormItem
      name={getFormGeneratorFieldPathName(metadata)}
      valuePropName={valuePropName}
      label={
        metadata.label ? (
          <span className="form-field-label">
            {metadata.label}
            {metadata.labelSuffix && <span className="label-suffix caption">{metadata.labelSuffix}</span>}
          </span>
        ) : null
      }
      tooltip={metadata.labelTooltip}
      className={classNames('form-generator-field', getFormGenratorFieldTestId(metadata.id.toString()), className)}
      rules={fieldRules}
      help={metadata.help}
      hidden={metadata.hidden}
    >
      {getFormField(metadata)}
    </StyledFormItem>
  );
};
