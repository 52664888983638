import React, { ReactNode } from 'react';
import { Icons } from 'Components/Primitives/Icon/Icon.types';
import classNames from 'classnames';

import Staircase from 'Assets/Icons/staircase.svg?react';
import Home from 'Assets/Icons/home.svg?react';
import HomeFull from 'Assets/Icons/home_full.svg?react';
import Email from 'Assets/Icons/email.svg?react';
import EmailSearch from 'Assets/Icons/email_search.svg?react';
import EmailSearchFull from 'Assets/Icons/email_search_full.svg?react';
import EmailSend from 'Assets/Icons/email_send.svg?react';
import SentimentOutline from 'Assets/Icons/sentiment_outline.svg?react';
import SentimentPositive from 'Assets/Icons/sentiment_positive.svg?react';
import SentimentNegative from 'Assets/Icons/sentiment_negative.svg?react';
import SentimentNeutral from 'Assets/Icons/sentiment_neutral.svg?react';
import MailOpen from 'Assets/Icons/mail_open.svg?react';
import MailClosed from 'Assets/Icons/mail_closed.svg?react';
import TicketsOpen from 'Assets/Icons/tickets_open.svg?react';
import TicketsClosed from 'Assets/Icons/tickets_closed.svg?react';
import Plus from 'Assets/Icons/plus.svg?react';
import PlusSquare from 'Assets/Icons/plus_square.svg?react';
import PlusSquareFull from 'Assets/Icons/plus_square_full.svg?react';
import Minus from 'Assets/Icons/minus.svg?react';
import User from 'Assets/Icons/user.svg?react';
import Users from 'Assets/Icons/users.svg?react';
import UsersFull from 'Assets/Icons/users_full.svg?react';
import UserAdd from 'Assets/Icons/user_add.svg?react';
import UserAddFull from 'Assets/Icons/user_add_full.svg?react';
import Logout from 'Assets/Icons/logout.svg?react';
import Columns from 'Assets/Icons/columns.svg?react';
import Filters from 'Assets/Icons/filters.svg?react';
import Save from 'Assets/Icons/save.svg?react';
import Edit from 'Assets/Icons/edit.svg?react';
import Close from 'Assets/Icons/close.svg?react';
import CloseRound from 'Assets/Icons/close_round.svg?react';
import CloseSquare from 'Assets/Icons/close_square.svg?react';
import Search from 'Assets/Icons/search.svg?react';
import ChevronUp from 'Assets/Icons/chevron_up.svg?react';
import ChevronDown from 'Assets/Icons/chevron_down.svg?react';
import ChevronRight from 'Assets/Icons/chevron_right.svg?react';
import ChevronLeft from 'Assets/Icons/chevron_left.svg?react';
import ArrowRight from 'Assets/Icons/arrow_right.svg?react';
import ArrowLeft from 'Assets/Icons/arrow_left.svg?react';
import ArrowUp from 'Assets/Icons/arrow_up.svg?react';
import ArrowDown from 'Assets/Icons/arrow_down.svg?react';
import ArrowTwoDirections from 'Assets/Icons/arrow_two_directions.svg?react';
import Calendar from 'Assets/Icons/calendar.svg?react';
import CalendarMimimal from 'Assets/Icons/calendar_minimal.svg?react';
import Cogwheel from 'Assets/Icons/cogwheel.svg?react';
import CogwheelFull from 'Assets/Icons/cogwheel_full.svg?react';
import Trash from 'Assets/Icons/trash.svg?react';
import V from 'Assets/Icons/v.svg?react';
import Video from 'Assets/Icons/video.svg?react';
import StairsUp from 'Assets/Icons/stairs_up.svg?react';
import StairsDown from 'Assets/Icons/stairs_down.svg?react';
import StairsLevel from 'Assets/Icons/stairs_level.svg?react';
import Chat from 'Assets/Icons/chat.svg?react';
import ChatAlert from 'Assets/Icons/chat_alert.svg?react';
import Reports from 'Assets/Icons/reports.svg?react';
import ReportsFull from 'Assets/Icons/reports_full.svg?react';
import DocOpen from 'Assets/Icons/doc_open.svg?react';
import DocFolded from 'Assets/Icons/doc_folded.svg?react';
import DocPDF from 'Assets/Icons/doc_pdf.svg?react';
import InfoSquare from 'Assets/Icons/info_square.svg?react';
import InfoCircle from 'Assets/Icons/info_circle.svg?react';
import Download from 'Assets/Icons/download.svg?react';
import Bubbles from 'Assets/Icons/bubbles.svg?react';
import MoreHorizontal from 'Assets/Icons/more_horizontal.svg?react';
import MoreVertical from 'Assets/Icons/more_vertical.svg?react';
import ActivityOutbound from 'Assets/Icons/activity_outbound.svg?react';
import ActivityInbound from 'Assets/Icons/activity_inbound.svg?react';
import Time from 'Assets/Icons/time.svg?react';
import UploadFile from 'Assets/Icons/upload_file.svg?react';
import Integrate from 'Assets/Icons/integrate.svg?react';
import IntegrateCancel from 'Assets/Icons/integrate_cancel.svg?react';
import Customer from 'Assets/Icons/customer.svg?react';
import CustomerFull from 'Assets/Icons/customer_full.svg?react';
import CustomerAdd from 'Assets/Icons/customer_add.svg?react';
import CustomerAddFull from 'Assets/Icons/customer_add_full.svg?react';
import SandClock from 'Assets/Icons/sand_clock.svg?react';
import Layers from 'Assets/Icons/layers.svg?react';
import Dollar from 'Assets/Icons/dollar.svg?react';
import Journey from 'Assets/Icons/journey.svg?react';
import EventTicket from 'Assets/Icons/event_ticket.svg?react';
import Copy from 'Assets/Icons/copy.svg?react';
import DragNDrop from 'Assets/Icons/drag_n_drop.svg?react';
import DataSyncPositive from 'Assets/Icons/data_sync_positive.svg?react';
import DataSyncNegative from 'Assets/Icons/data_sync_negative.svg?react';
import DataSyncOutline from 'Assets/Icons/data_sync_outline.svg?react';
import DataSyncFull from 'Assets/Icons/data_sync_full.svg?react';
import Notification from 'Assets/Icons/notification.svg?react';
import NotificationAlert from 'Assets/Icons/notification_alert.svg?react';
import NotificationConfig from 'Assets/Icons/notification_config.svg?react';
import SearchCommunication from 'Assets/Icons/search_communication.svg?react';
import SearchCommunicationFull from 'Assets/Icons/search_communication_full.svg?react';
import Folder from 'Assets/Icons/folder.svg?react';
import Heart from 'Assets/Icons/heart.svg?react';
import WarningCircle from 'Assets/Icons/warning_circle.svg?react';
import CheckSquare from 'Assets/Icons/check_square.svg?react';
import CheckSquareFull from 'Assets/Icons/check_square_full.svg?react';
import SyncNow from 'Assets/Icons/sync_now.svg?react';
import GoTo from 'Assets/Icons/goto.svg?react';
import Link from 'Assets/Icons/link.svg?react';
import LinkCopy from 'Assets/Icons/link_copy.svg?react';
import Briefcase from 'Assets/Icons/briefcase.svg?react';
import PulseSquare from 'Assets/Icons/pulse_square.svg?react';
import PulseSquareFull from 'Assets/Icons/pulse_square_full.svg?react';
import Relationship from 'Assets/Icons/relationship.svg?react';
import Adjust from 'Assets/Icons/adjust.svg?react';
import Megaphone from 'Assets/Icons/megaphone.svg?react';
import MegaphoneFull from 'Assets/Icons/megaphone_full.svg?react';

import {
  WarningOutlined,
  WarningFilled,
  MinusCircleOutlined,
  CheckCircleFilled,
  LoadingOutlined
} from '@ant-design/icons';

export interface IconProps {
  icon: Icons;
  className?: string;
  style?: React.CSSProperties;
  'data-testid'?: string;
}

interface CustomIconProps extends IconProps {
  iconNode: ReactNode;
}
const CustomIcon: React.FC<CustomIconProps> = (props) => {
  const { iconNode, ...rest } = props;
  return (
    <span {...rest} className={classNames('anticon', props.className)}>
      {iconNode}
    </span>
  );
};

const iconMapper: Record<Icons, React.FC<IconProps>> = {
  /*
  // Ant design icons
  */
  [Icons.Warning]: (props: IconProps) => <WarningOutlined {...props} />,
  [Icons.WarningFilled]: (props: IconProps) => <WarningFilled {...props} />,
  [Icons.MinusCircle]: (props: IconProps) => <MinusCircleOutlined {...props} />,
  [Icons.CheckCircleFilled]: (props: IconProps) => <CheckCircleFilled {...props} />,
  [Icons.LoadingCircleSpinner]: (props: IconProps) => <LoadingOutlined {...props} />,

  /*
  // Custome icons
  */
  [Icons.Staircase]: (props: IconProps) => <CustomIcon {...props} iconNode={<Staircase />} />,
  [Icons.Home]: (props: IconProps) => <CustomIcon {...props} iconNode={<Home />} />,
  [Icons.HomeFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<HomeFull />} />,
  [Icons.Email]: (props: IconProps) => <CustomIcon {...props} iconNode={<Email />} />,
  [Icons.EmailSearch]: (props: IconProps) => <CustomIcon {...props} iconNode={<EmailSearch />} />,
  [Icons.EmailSearchFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<EmailSearchFull />} />,
  [Icons.EmailSend]: (props: IconProps) => <CustomIcon {...props} iconNode={<EmailSend />} />,
  [Icons.Plus]: (props: IconProps) => <CustomIcon {...props} iconNode={<Plus />} />,
  [Icons.PlusSquare]: (props: IconProps) => <CustomIcon {...props} iconNode={<PlusSquare />} />,
  [Icons.PlusSquareFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<PlusSquareFull />} />,
  [Icons.Minus]: (props: IconProps) => <CustomIcon {...props} iconNode={<Minus />} />,
  [Icons.Logout]: (props: IconProps) => <CustomIcon {...props} iconNode={<Logout />} />,
  [Icons.User]: (props: IconProps) => <CustomIcon {...props} iconNode={<User />} />,
  [Icons.Users]: (props: IconProps) => <CustomIcon {...props} iconNode={<Users />} />,
  [Icons.UsersFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<UsersFull />} />,
  [Icons.Cogwheel]: (props: IconProps) => <CustomIcon {...props} iconNode={<Cogwheel />} />,
  [Icons.CogwheelFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<CogwheelFull />} />,
  [Icons.UserAdd]: (props: IconProps) => <CustomIcon {...props} iconNode={<UserAdd />} />,
  [Icons.UserAddFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<UserAddFull />} />,
  [Icons.Columns]: (props: IconProps) => <CustomIcon {...props} iconNode={<Columns />} />,
  [Icons.Filter]: (props: IconProps) => <CustomIcon {...props} iconNode={<Filters />} />,
  [Icons.SentimentOutline]: (props: IconProps) => <CustomIcon {...props} iconNode={<SentimentOutline />} />,
  [Icons.SentimentPositive]: (props: IconProps) => <CustomIcon {...props} iconNode={<SentimentPositive />} />,
  [Icons.SentimentNeutral]: (props: IconProps) => <CustomIcon {...props} iconNode={<SentimentNeutral />} />,
  [Icons.SentimentNegative]: (props: IconProps) => <CustomIcon {...props} iconNode={<SentimentNegative />} />,
  [Icons.MailOpen]: (props: IconProps) => <CustomIcon {...props} iconNode={<MailOpen />} />,
  [Icons.MailClosed]: (props: IconProps) => <CustomIcon {...props} iconNode={<MailClosed />} />,
  [Icons.Save]: (props: IconProps) => <CustomIcon {...props} iconNode={<Save />} />,
  [Icons.Edit]: (props: IconProps) => <CustomIcon {...props} iconNode={<Edit />} />,
  [Icons.Close]: (props: IconProps) => <CustomIcon {...props} iconNode={<Close />} />,
  [Icons.CloseRound]: (props: IconProps) => <CustomIcon {...props} iconNode={<CloseRound />} />,
  [Icons.CloseSquare]: (props: IconProps) => <CustomIcon {...props} iconNode={<CloseSquare />} />,
  [Icons.Search]: (props: IconProps) => <CustomIcon {...props} iconNode={<Search />} />,
  [Icons.ChevronUp]: (props: IconProps) => <CustomIcon {...props} iconNode={<ChevronUp />} />,
  [Icons.ChevronDown]: (props: IconProps) => <CustomIcon {...props} iconNode={<ChevronDown />} />,
  [Icons.ChevronLeft]: (props: IconProps) => <CustomIcon {...props} iconNode={<ChevronLeft />} />,
  [Icons.ChevronRight]: (props: IconProps) => <CustomIcon {...props} iconNode={<ChevronRight />} />,
  [Icons.Calendar]: (props: IconProps) => <CustomIcon {...props} iconNode={<Calendar />} />,
  [Icons.CalendarMinimal]: (props: IconProps) => <CustomIcon {...props} iconNode={<CalendarMimimal />} />,
  [Icons.ArrowLeft]: (props: IconProps) => <CustomIcon {...props} iconNode={<ArrowLeft />} />,
  [Icons.ArrowRight]: (props: IconProps) => <CustomIcon {...props} iconNode={<ArrowRight />} />,
  [Icons.ArrowDown]: (props: IconProps) => <CustomIcon {...props} iconNode={<ArrowDown />} />,
  [Icons.ArrowUp]: (props: IconProps) => <CustomIcon {...props} iconNode={<ArrowUp />} />,
  [Icons.ArrowTwoDirections]: (props: IconProps) => <CustomIcon {...props} iconNode={<ArrowTwoDirections />} />,
  [Icons.Trash]: (props: IconProps) => <CustomIcon {...props} iconNode={<Trash />} />,
  [Icons.V]: (props: IconProps) => <CustomIcon {...props} iconNode={<V />} />,
  [Icons.Video]: (props: IconProps) => <CustomIcon {...props} iconNode={<Video />} />,
  [Icons.StairsUp]: (props: IconProps) => <CustomIcon {...props} iconNode={<StairsUp />} />,
  [Icons.StairsDown]: (props: IconProps) => <CustomIcon {...props} iconNode={<StairsDown />} />,
  [Icons.StairsLevel]: (props: IconProps) => <CustomIcon {...props} iconNode={<StairsLevel />} />,
  [Icons.TicketsOpen]: (props: IconProps) => <CustomIcon {...props} iconNode={<TicketsOpen />} />,
  [Icons.TicketsClosed]: (props: IconProps) => <CustomIcon {...props} iconNode={<TicketsClosed />} />,
  [Icons.Chat]: (props: IconProps) => <CustomIcon {...props} iconNode={<Chat />} />,
  [Icons.ChatAlert]: (props: IconProps) => <CustomIcon {...props} iconNode={<ChatAlert />} />,
  [Icons.Reports]: (props: IconProps) => <CustomIcon {...props} iconNode={<Reports />} />,
  [Icons.ReportsFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<ReportsFull />} />,
  [Icons.DocOpen]: (props: IconProps) => <CustomIcon {...props} iconNode={<DocOpen />} />,
  [Icons.DocFolded]: (props: IconProps) => <CustomIcon {...props} iconNode={<DocFolded />} />,
  [Icons.InfoSquare]: (props: IconProps) => <CustomIcon {...props} iconNode={<InfoSquare />} />,
  [Icons.InfoCircle]: (props: IconProps) => <CustomIcon {...props} iconNode={<InfoCircle />} />,
  [Icons.Download]: (props: IconProps) => <CustomIcon {...props} iconNode={<Download />} />,
  [Icons.Bubbles]: (props: IconProps) => <CustomIcon {...props} iconNode={<Bubbles />} />,
  [Icons.MoreHorizontal]: (props: IconProps) => <CustomIcon {...props} iconNode={<MoreHorizontal />} />,
  [Icons.MoreVertical]: (props: IconProps) => <CustomIcon {...props} iconNode={<MoreVertical />} />,
  [Icons.ActivityOutbound]: (props: IconProps) => <CustomIcon {...props} iconNode={<ActivityOutbound />} />,
  [Icons.ActivityInbound]: (props: IconProps) => <CustomIcon {...props} iconNode={<ActivityInbound />} />,
  [Icons.Time]: (props: IconProps) => <CustomIcon {...props} iconNode={<Time />} />,
  [Icons.UploadFile]: (props: IconProps) => <CustomIcon {...props} iconNode={<UploadFile />} />,
  [Icons.Integrate]: (props: IconProps) => <CustomIcon {...props} iconNode={<Integrate />} />,
  [Icons.IntegrateCancel]: (props: IconProps) => <CustomIcon {...props} iconNode={<IntegrateCancel />} />,
  [Icons.Customer]: (props: IconProps) => <CustomIcon {...props} iconNode={<Customer />} />,
  [Icons.CustomerFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<CustomerFull />} />,
  [Icons.CustomerAdd]: (props: IconProps) => <CustomIcon {...props} iconNode={<CustomerAdd />} />,
  [Icons.CustomerAddFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<CustomerAddFull />} />,
  [Icons.SandClock]: (props: IconProps) => <CustomIcon {...props} iconNode={<SandClock />} />,
  [Icons.Layers]: (props: IconProps) => <CustomIcon {...props} iconNode={<Layers />} />,
  [Icons.Dollar]: (props: IconProps) => <CustomIcon {...props} iconNode={<Dollar />} />,
  [Icons.Journey]: (props: IconProps) => <CustomIcon {...props} iconNode={<Journey />} />,
  [Icons.EventTicket]: (props: IconProps) => <CustomIcon {...props} iconNode={<EventTicket />} />,
  [Icons.Copy]: (props: IconProps) => <CustomIcon {...props} iconNode={<Copy />} />,
  [Icons.DragNDrop]: (props: IconProps) => <CustomIcon {...props} iconNode={<DragNDrop />} />,
  [Icons.DataSyncOutline]: (props: IconProps) => <CustomIcon {...props} iconNode={<DataSyncOutline />} />,
  [Icons.DataSyncFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<DataSyncFull />} />,
  [Icons.DataSyncPositive]: (props: IconProps) => <CustomIcon {...props} iconNode={<DataSyncPositive />} />,
  [Icons.DataSyncNegative]: (props: IconProps) => <CustomIcon {...props} iconNode={<DataSyncNegative />} />,
  [Icons.Notification]: (props: IconProps) => <CustomIcon {...props} iconNode={<Notification />} />,
  [Icons.NotificationAlert]: (props: IconProps) => <CustomIcon {...props} iconNode={<NotificationAlert />} />,
  [Icons.NotificationConfig]: (props: IconProps) => <CustomIcon {...props} iconNode={<NotificationConfig />} />,
  [Icons.SearchCommunication]: (props: IconProps) => <CustomIcon {...props} iconNode={<SearchCommunication />} />,
  [Icons.SearchCommunicationFull]: (props: IconProps) => (
    <CustomIcon {...props} iconNode={<SearchCommunicationFull />} />
  ),
  [Icons.Folder]: (props: IconProps) => <CustomIcon {...props} iconNode={<Folder />} />,
  [Icons.Heart]: (props: IconProps) => <CustomIcon {...props} iconNode={<Heart />} />,
  [Icons.WarningCircle]: (props: IconProps) => <CustomIcon {...props} iconNode={<WarningCircle />} />,
  [Icons.CheckSquare]: (props: IconProps) => <CustomIcon {...props} iconNode={<CheckSquare />} />,
  [Icons.CheckSquareFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<CheckSquareFull />} />,
  [Icons.SyncNow]: (props: IconProps) => <CustomIcon {...props} iconNode={<SyncNow />} />,
  [Icons.GoTo]: (props: IconProps) => <CustomIcon {...props} iconNode={<GoTo />} />,
  [Icons.Link]: (props: IconProps) => <CustomIcon {...props} iconNode={<Link />} />,
  [Icons.LinkCopy]: (props: IconProps) => <CustomIcon {...props} iconNode={<LinkCopy />} />,
  [Icons.Briefcase]: (props: IconProps) => <CustomIcon {...props} iconNode={<Briefcase />} />,
  [Icons.DocPDF]: (props: IconProps) => <CustomIcon {...props} iconNode={<DocPDF />} />,
  [Icons.PulseSquare]: (props: IconProps) => <CustomIcon {...props} iconNode={<PulseSquare />} />,
  [Icons.PulseSquareFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<PulseSquareFull />} />,
  [Icons.Relationship]: (props: IconProps) => <CustomIcon {...props} iconNode={<Relationship />} />,
  [Icons.Adjust]: (props: IconProps) => <CustomIcon {...props} iconNode={<Adjust />} />,
  [Icons.Megaphone]: (props: IconProps) => <CustomIcon {...props} iconNode={<Megaphone />} />,
  [Icons.MegaphoneFull]: (props: IconProps) => <CustomIcon {...props} iconNode={<MegaphoneFull />} />
};

export const Icon: React.FC<IconProps> = (props) => iconMapper[props.icon](props);
