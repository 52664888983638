import { GainsightEntry } from 'Providers/Gainsight';

export const isGainsightApp = (): boolean => false;

const ENTRIES_WITH_PAGE_TOP_HEADER: GainsightEntry[] = [
  GainsightEntry.Dashboard,
  GainsightEntry.Customer,
  GainsightEntry.User,
  GainsightEntry.Stakeholder
];
export const shouldShowPageTopHeader = (entry: GainsightEntry | undefined): boolean =>
  entry ? ENTRIES_WITH_PAGE_TOP_HEADER.includes(entry) : false;
