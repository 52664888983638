import React from 'react';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'Providers/Authentication';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UiUtilsProvider } from 'Providers/UiUtils';
import { RecoilRoot } from 'recoil';
import { productionEnv } from 'Utils/environment';
import { ErrorFallback } from 'Views/ErrorPage';
import { lazyWithRetries } from 'Utils/lazyWithRetries';
import { GainsightProvider } from 'Providers/Gainsight';

const GainsightApp = lazyWithRetries(() => import('Views/GainsightApp/GainsightApp'));

if (productionEnv) {
  Sentry.init({
    dsn: 'https://935eaba1e02f4ab380fe7b40a182fb16@o508855.ingest.sentry.io/5602205',
    integrations: [Sentry.browserTracingIntegration()],
    denyUrls: ['localhost'],
    tracesSampleRate: 1.0
  });
}

const queryClient = new QueryClient();

const DesktopAppEntry: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <AuthProvider>
            <UiUtilsProvider>
              <GainsightProvider>
                <GainsightApp />
              </GainsightProvider>
            </UiUtilsProvider>
          </AuthProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default DesktopAppEntry;
