//
// The entry logic is to be implemented here once the requirements are clear
// based on the Entry enum, different behaviour can be implemented

import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

enum GainsightEntry {
  Dashboard = 'Dashboard',
  Customer = 'Customer',
  User = 'User',
  Stakeholder = 'Stakeholder'
}

interface GainsightState {
  entry: GainsightEntry | undefined;
}

const GainsightContext = React.createContext<GainsightState>({ entry: undefined });

const GainsightProvider: React.FC<{ children?: ReactNode }> = (props) => {
  const [entry, setEntry] = useState<GainsightEntry>();
  const location = useLocation();

  const getGainsightEntry = useCallback((path: string): GainsightEntry | undefined => {
    if (path === '/' || path.startsWith('/dashboard')) return GainsightEntry.Dashboard;
    if (path.startsWith('/customers')) return GainsightEntry.Customer;
    if (path.startsWith('/users')) return GainsightEntry.User;
    if (path.startsWith('/stakeholder')) return GainsightEntry.Stakeholder;
    return undefined;
  }, []);

  useEffect(() => {
    setEntry(getGainsightEntry(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GainsightContext.Provider value={{ entry }} {...props} />;
};

const useGainsightContext = (): GainsightState => React.useContext(GainsightContext);

export { GainsightEntry, GainsightProvider, useGainsightContext };
