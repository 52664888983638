import { ReactNode } from 'react';
import { SelectOption, SelectProps, TreeNode } from 'Components/Primitives';
import { ListType } from 'Types/Shared.types';
import { FormListFieldData, Rule } from 'antd/es/form';
import { PredefinedDateRange } from 'Types/DateRange.types';

export type RecursivePartial<T> =
  NonNullable<T> extends object
    ? {
        [P in keyof T]?: NonNullable<T[P]> extends (infer U)[]
          ? RecursivePartial<U>[]
          : NonNullable<T[P]> extends object
            ? RecursivePartial<T[P]>
            : T[P];
      }
    : T;

export enum FormFieldType {
  String = 'String',
  Number = 'Number',
  Password = 'Password',
  Boolean = 'Boolean',
  Date = 'Date',
  DateTime = 'DateTime',
  DateRange = 'DateRange',
  List = 'List',
  TreeList = 'TreeList',
  StringArray = 'StringArray'
}

export type FieldTypeBase = Exclude<
  FormFieldType,
  | FormFieldType.List
  | FormFieldType.TreeList
  | FormFieldType.Date
  | FormFieldType.DateTime
  | FormFieldType.StringArray
  | FormFieldType.Boolean
>;

interface FormFieldMetadataBase {
  id: number | string;
  dataPath?: string[];
  fieldType: FieldTypeBase;
  label?: string;
  labelTooltip?: string;
  labelSuffix?: ReactNode;
  disabled?: boolean;
  allowClear?: boolean;
  required?: boolean;
  help?: ReactNode;
  rules?: Rule[];
  hidden?: boolean;
  colSize?: 'col-2' | 'col-3' | 'col-4' | 'col-6' | 'col-8' | 'col-12';
  placeholder?: string;
  stringOptions?: { mode?: 'text' | 'textArea' };
  listOptions?: FormListFieldOptions;
  dateOptions?: { withPrefix?: boolean; predefinedOptions?: PredefinedDateRange[] | 'all' };
  numberOptions?: { isPercent?: boolean };
}

interface FormListFieldOptions {
  mode?: SelectProps['mode'];
  maxTagCount?: number | 'responsive';
  popupMatchSelectWidth?: boolean;
  treeNodeLabelProp?: 'displayTitle' | 'title';
  emptyAsAll?: boolean;
}

export interface FormFieldMetadataString extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.String;
}

export interface FormFieldMetadataList extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.List;
  listType?: ListType;
  customOptions?: SelectOption[];
}

export interface FormFieldMetadataTreeList extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.TreeList;
  treeOptions: TreeNode[];
}

export interface FormFieldMetadataDate extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.Date | FormFieldType.DateTime;
  disabledDate?(current: Date | undefined): boolean;
}

export interface FormFieldMetadataStringArray extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.StringArray;
  disableListInput?(field: FormListFieldData, index: number): boolean;
}

export interface FormFieldMetadataBoolean extends Omit<FormFieldMetadataBase, 'fieldType'> {
  fieldType: FormFieldType.Boolean;
  booleanRendering?: 'select' | 'checkbox';
}

export type FormFieldMetadata =
  | FormFieldMetadataBase
  | FormFieldMetadataList
  | FormFieldMetadataTreeList
  | FormFieldMetadataDate
  | FormFieldMetadataStringArray
  | FormFieldMetadataBoolean;
